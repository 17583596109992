<template>
  <div id="unggas-nasional-data-populasi-flock-list" data-app>

    <!--begin::Engage Widget 1-->
    <div class="card card-custom card-stretch gutter-b">
        <div class="card-body d-flex p-0 bg-white">
            <div class="flex-grow-1 p-8 card-rounded bgi-no-repeat d-flex align-items-center"
                style="background-position: right bottom; background-size: auto 100%; background-image: url(media/svg/humans/custom-8.svg)">

                <div class="row w-100">
                    <div class="col-12 col-sm-9">
                        <div class="row mb-2">
                            <button 
                                @click="$router.push('/unggas-nasional/populasi')" 
                                type="button" 
                                class="btn btn-link-primary font-weight-bolder px-8 font-size-lg m-2"
                                v-b-tooltip.hover title="List Farm"
                            >
                                <span class="svg-icon svg-icon-lg">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Navigation/Angle-double-left.svg" />
                                    <!--end::Svg Icon-->
                                </span>
                                List Farm
                            </button>
                            <b-form-checkbox
                            id="estimate"
                            class="m-2"
                            v-model="estimate"
                            name="estimate"
                            >
                            Estimasi {{ (getEstimate ? "Aktif": "") }}
                            </b-form-checkbox>
                        </div>
                        <div class="separator separator-dashed separator-border-2 mb-5"></div>
                        <h4 class="text-danger font-weight-bolder">Search Flock</h4>
                        <div class="d-flex flex-center py-2 px-6 bg-light rounded">
                            <span class="svg-icon svg-icon-lg svg-icon-info">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/General/Search.svg" />
                                <!--end::Svg Icon-->
                            </span>
                            <input type="text" v-model="search" class="form-control border-0 font-weight-bold pl-2 bg-light" placeholder="Search Your Flock">
                        </div>
                    </div>
                    <div class="col-12 col-sm-3">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end::Engage Widget 1-->

    <div v-if="!isBusy">
        <!--Begin::Row-->
        <b-row v-if="filteredItem.length">
            <b-col cols="12" lg="4" md="6" sm="6" 
                v-for="(val, key) in filteredItem" 
                :key="key" 
                class="py-0"
            >
                <!--begin::Stats--> 
                <div class="card card-custom bgi-no-repeat card-stretch gutter-b cursor-pointer"
                    :style="`background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/${getSVG()}`"
                    @contextmenu.prevent="$refs.menu.open($event, val)"
                    @click="openKandang(val)"
                >

                    <!--begin::body-->
                    <div class="card-body d-flex flex-column">
                        <div class="flex-grow-1">
                            <!--begin::Info-->
                            <div class="d-flex align-items-center pr-2 mb-2">
                                <span class="text-dark font-weight-bolder text-hover-primary font-size-h4">
                                    Flock {{ val.flock }}
                                </span>
                                <span v-if="val.peringatan" v-b-tooltip.hover :title="val.peringatan" class="svg-icon svg-icon-lg svg-icon-danger ml-2 m-0">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                                    <!--end::Svg Icon-->
                                </span>
                            </div>
                            <!--end::Info-->

                            <!--begin::Desc-->
                            <p class="text-dark-50 font-weight-normal font-size-sm mt-2">
                                Populasi
                                <span v-for="(child, indexChild) in val.populasi" :key="indexChild">
                                    <span :class="{'text-danger': getEstimate}">{{ (indexChild > 0 ? ', ':'') }}{{ child.sex }} {{ (child.chickin - child.deplesi - child.transfer - child.afkir) | nominal }} ekor</span>
                                </span>
                            </p>
                            <!--end::Desc-->
                        </div>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Stats-->
            </b-col>
        </b-row>
        <!--End::Row-->

        <div v-else class="card card-custom gutter-b mt-8" style="height: 150px">
            <!--begin::Body-->
            <div class="card-body d-flex align-items-center justify-content-between flex-wrap">
                <div class="mr-2">
                    <h3 class="font-weight-bolder">Tidak ada populasi</h3>
                    <div class="text-dark-50 font-size-lg mt-2">
                        Silahkan tambah data chickin terlebih dahulu
                    </div>
                </div>
                <a @click="$router.push('/unggas-nasional/populasi')" class="cursor-pointer btn btn-primary font-weight-bold py-3 px-6">List Farm</a>
            </div>
            <!--end::Body-->
        </div>
    </div>
    <div v-else>
        <!--Begin::Row-->
        <b-row>
            <b-col cols="12" lg="4" md="6" sm="6" class="py-0">
                <!--begin::Stats--> 
                <div class="card card-custom bgi-no-repeat card-stretch gutter-b cursor-pointer"
                    :style="`background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/${getSVG()}`">
                    <!--begin::body-->
                    <div class="card-body d-flex flex-column">
                        <CodeLoader></CodeLoader>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Stats-->
            </b-col>
            <b-col cols="12" lg="4" md="6" sm="6" class="py-0">
                <!--begin::Stats--> 
                <div class="card card-custom bgi-no-repeat card-stretch gutter-b cursor-pointer"
                    :style="`background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/${getSVG()}`">
                    <!--begin::body-->
                    <div class="card-body d-flex flex-column">
                        <CodeLoader></CodeLoader>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Stats-->
            </b-col>
            <b-col cols="12" lg="4" md="6" sm="6" class="py-0">
                <!--begin::Stats--> 
                <div class="card card-custom bgi-no-repeat card-stretch gutter-b cursor-pointer"
                    :style="`background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/${getSVG()}`">
                    <!--begin::body-->
                    <div class="card-body d-flex flex-column">
                        <CodeLoader></CodeLoader>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Stats-->
            </b-col>
        </b-row>
        <!--End::Row-->
    </div>

    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <div class="card card-custom h-100">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label font-weight-bolder text-dark">Kandang</span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">List kandang pada flock </span>
                </h3>
                <div class="card-toolbar">
                    <a @click="getDataKandang()" class="btn btn-icon btn-sm btn-success mr-1" v-b-tooltip.hover title="Reload Modal">
                        <i class="ki ki-reload icon-nm"></i>
                    </a>
                    <a @click="closeKandang()" class="btn btn-icon btn-sm btn-danger" v-b-tooltip.hover title="Close Modal">
                        <i class="ki ki-close icon-nm"></i>
                    </a>
                </div>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body py-0">
                <div class="d-flex flex-center py-1 px-6 bg-light rounded my-5">
                    <span class="svg-icon svg-icon-sm svg-icon-info">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/General/Search.svg" />
                        <!--end::Svg Icon-->
                    </span>
					<input type="text" v-model="searchKandang" class="form-control border-0 font-weight-bold pl-2 bg-light" placeholder="Ketik untuk mencari nama kandang">
				</div>
                        
                <!--begin::Table-->
                <div class="table-responsive h-100">
                    <table class="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                        <thead>
                            <tr class="text-uppercase">
                                <th @click="sort('kandang')" class="pl-0 cursor-pointer">
                                    <span :class="{ 'text-primary': currentSort == 'kandang' }">Kandang</span>
                                    <span v-if="currentSort == 'kandang' && currentSortDir == 'asc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Navigation/Down-2.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span v-if="currentSort == 'kandang' && currentSortDir == 'desc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Navigation/Up-2.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                </th>
                                <th @click="sort('umur')" class="cursor-pointer d-none d-sm-table-cell">
                                    <span :class="{ 'text-primary': currentSort == 'umur' }">Strain/Umur</span>
                                    <span v-if="currentSort == 'umur' && currentSortDir == 'asc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Navigation/Down-2.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span v-if="currentSort == 'umur' && currentSortDir == 'desc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Navigation/Up-2.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                </th>
                                <th class="d-none d-md-table-cell">Status</th>
                                <th class="pr-0 text-right">Act</th>
                            </tr>
                        </thead>
                        <tbody v-if="!isLoading">
                            <tr v-for="(val, key) in listKandang" :key="key">
                                <td class="pl-0">
                                    <div class="d-block"> 
                                        <span v-if="val.peringatan" v-b-tooltip.hover :title="val.peringatan" :id="`peringatan-${key}`" class="cursor-pointer svg-icon svg-icon-lg svg-icon-danger mr-2 m-0">
                                            <!--begin::Svg Icon-->
                                            <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                                            <!--end::Svg Icon-->
                                        </span> 
                                        <span class="cursor-pointer text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{{ val.kandang }}</span>
                                    </div>
                                    <span :class="{'text-danger': getEstimate, 'text-muted': !getEstimate}" class="font-weight-bold">Populasi {{ (val.chickin - val.deplesi - val.transfer - val.afkir) | nominal }} ekor</span>
                                </td>
                                <td class="d-none d-sm-table-cell">
                                    <span class="d-block text-dark-75 font-weight-bolder font-size-lg ml-1">
                                        {{ val.strain }}
                                    </span>
                                    <b-badge variant="light" class="mx-1 mt-1" v-for="(umr, idu) in viewUmur(val.umur)" :key="idu">{{ umr }}</b-badge>
                                    <span class="text-muted">(mgg)</span>
                                </td>
                                <td class="d-none d-md-table-cell">
                                    <b-badge class="mx-1" v-for="(res, ids) in viewStatus(val.umur)" :key="ids" :variant="res.color">{{ res.text }}</b-badge>
                                </td>
                                <td class="pr-0 text-right">
                                    <b-dropdown size="sm" variant="hover-primary" class="btn-light rounded">
                                        <template v-slot:button-content>
                                            <span class="svg-icon svg-icon-md svg-icon-primary">
                                                <!--begin::Svg Icon-->
                                                <inline-svg src="media/svg/icons/Code/Compiling.svg"/>
                                                <!--end::Svg Icon-->
                                            </span>
                                            <span class="d-none d-sm-inline">Action</span>
                                        </template>                                        
                                        <b-dropdown-item @click="openDialogForm('FormDetail', val)">
                                            <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                                <!--begin::Svg Icon-->
                                                <inline-svg src="media/svg/poultry-farm/kandang.svg"/>
                                                <!--end::Svg Icon-->
                                            </span>
                                            <span class="align-self-center">Detail</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item  @click="openDialogForm('FormDeplesi', val)">
                                            <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                                <!--begin::Svg Icon-->
                                                <inline-svg src="media/svg/poultry-farm/deplesi.svg"/>
                                                <!--end::Svg Icon-->
                                            </span>
                                            <span class="align-self-center">Deplesi</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item  @click="openDialogForm('FormTransfer', val)">
                                            <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                                <!--begin::Svg Icon-->
                                                <inline-svg src="media/svg/poultry-farm/transfer-cage.svg"/>
                                                <!--end::Svg Icon-->
                                            </span>
                                            <span class="align-self-center">Transfer</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item  @click="openDialogForm('FormFeedInTake', val)">
                                            <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                                <!--begin::Svg Icon-->
                                                <inline-svg src="media/svg/poultry-farm/transfer-cage.svg"/>
                                                <!--end::Svg Icon-->
                                            </span>
                                            <span class="align-self-center">Feed In Take</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item  @click="openDialogForm('FormProduksi', val)">
                                            <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                                <!--begin::Svg Icon-->
                                                <inline-svg src="media/svg/poultry-farm/production-eggs.svg"/>
                                                <!--end::Svg Icon-->
                                            </span>
                                            <span class="align-self-center">Produksi HE</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item  @click="openDialogForm('FormAfkir', val)">
                                            <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                                <!--begin::Svg Icon-->
                                                <inline-svg src="media/svg/poultry-farm/afkir.svg"/>
                                                <!--end::Svg Icon-->
                                            </span>
                                            <span class="align-self-center">Afkir</span>
                                        </b-dropdown-item>
                                    </b-dropdown>

                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td class="p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                                <td class="d-none d-sm-table-cell p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                                <td class="d-none d-md-table-cell p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                                <td class="d-none d-md-table-cell p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                            </tr>
                            
                            <tr>
                                <td class="p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                                <td class="d-none d-sm-table-cell p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                                <td class="d-none d-md-table-cell p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                                <td class="d-none d-md-table-cell p-3">
                                    <ContentLoader height="20"></ContentLoader>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Body-->
            <div class="card-footer">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"></b-pagination>
            </div>
        </div>
    </v-dialog>

    <v-dialog v-model="dialogDetail" persistent scrollable max-width="500px">
        <component v-if="curComponent" :sdata="sdataForm" @close-modal="closeDialogForm" v-bind:is="curComponent"></component>
    </v-dialog>

    <vue-context ref="menu" v-slot="{ data }"
        class="navi navi-hover"
        :lazy="false"
    >
        <li class="navi-item">
            <a class="navi-link active" href="#">
                <span class="navi-icon"><i class="flaticon2-analytics"></i></span>
                <span class="navi-text">Messages</span>
                <span class="navi-label">
                    <span class="label label-danger label-rounded">2</span>
                </span>
            </a>
        </li>
        <li class="navi-item">
            <a class="navi-link" href="#">
                <span class="navi-icon"><i class="flaticon2-pie-chart-2"></i></span>
                <span class="navi-text">{{ (data ? data.flock : null) }}</span>
            </a>
        </li>
        <li class="navi-item">
            <a class="navi-link" href="#">
                <span class="navi-icon"><i class="flaticon2-layers"></i></span>
                <span class="navi-text">Profile</span>
            </a>
        </li>
        <li class="navi-item">
            <a class="navi-link" href="#">
                <span class="navi-icon"><i class="flaticon2-box-1"></i></span>
                <span class="navi-text">Tasks</span>
                <span class="navi-label">
                    <span class="label label-warning label-rounded">5</span>
                </span>
            </a>
        </li>
    </vue-context>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CodeLoader, ContentLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PopulasiService from "@/core/services/api/nasional/populasi.service";
import ErrorService from "@/core/services/error.service";

import FormDetail from "@/view/pages/nasional/populasi/form/_Detail.vue";
import FormDeplesi from "@/view/pages/nasional/populasi/form/_Deplesi.vue";
import FormTransfer from "@/view/pages/nasional/populasi/form/_Transfer.vue";
import FormFeedInTake from "@/view/pages/nasional/populasi/form/_FeedInTake.vue";
import FormProduksi from "@/view/pages/nasional/populasi/form/_Produksi.vue";
import FormAfkir from "@/view/pages/nasional/populasi/form/_Afkir.vue";

import VueContext from 'vue-context';
import 'vue-context/src/sass/vue-context.scss';

export default {
  name: "unggas-nasional-data-populasi-flock-list",
  props: {
    idF: {
      required: true
    }
  },
  components: {
    VueContext,
    CodeLoader,
    ContentLoader,
    FormDetail,
    FormDeplesi,
    FormFeedInTake,
    FormTransfer,
    FormProduksi,
    FormAfkir,
  },  
  data() {
    return {
      dialogDetail: false,
      curComponent: null,
      sdataForm: null,
      search: '',
      items: [],
      dialog: false,
      isBusy: true,
      getEstimate: (window.localStorage.getItem("estimasi_populasi") == "true" ? true : false),
      paramKandang: null,
      isLoading: true,
      searchKandang: '',
      currentPage: 1,
      perPage: 8,
      currentSort:'kandang',
      currentSortDir:'asc',
      itemsKandang: [],
      svg: ['abstract-1.svg', 'abstract-2.svg', 'abstract-3.svg', 'abstract-4.svg', 'abstract-5.svg']
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    filteredItem() { 
        return this.items.filter((item) => item.flock.toLowerCase().includes(this.search.toLowerCase()));
    },
    estimate: {
        // getter
        get: function () {
            return window.localStorage.getItem("estimasi_populasi") || false
        },
        // setter
        set: function (newValue) {
            if (!this.isBusy) {
                window.localStorage.setItem("estimasi_populasi", newValue);
                this.getEstimate = newValue
                this.getData()
            }
        }
    },
    filteredKandang() {
        const result = this.itemsKandang.filter((row, index) => {
            if(row.kandang.toLowerCase().includes(this.searchKandang.toLowerCase())) return true;
        })
        return result
    },
    listKandang() {
        let sort = this.filteredKandang.slice(0).sort((a,b) => {
            let modifier = 1;
            if(this.currentSortDir === 'desc') modifier = -1;
            if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
            if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
            return 0;
        })
        const result = sort.filter((row, index) => {
            let start = (this.currentPage-1)*this.perPage;
            let end = this.currentPage*this.perPage;
            if(index >= start && index < end) return true;
        })
        return result
    },
    totalRows() {
        if (!this.filteredKandang) {
            return 1
        }
        return this.filteredKandang.length
    },
  },
  methods: {
    getSVG () {
        return this.svg[Math.floor(Math.random() * this.svg.length)];
    },
    viewUmur(val) {
        if(val == null) return []
        return val.split(",")
    },
    viewStatus(val) {
        const umur = val.split(",")
        let status = {
            grow: false,
            lay: false,
            afkir: false,
        }
        let result = []

        for (let index = 0; index < umur.length; index++) {
            if (umur[index] < 26 && !status.grow) {
                result.push({ color: "success", text: "Growing" })
                status.grow = true
            }
            else if (umur[index] >= 26 && umur[index] <= 55 && !status.lay) {
                result.push({ color: "primary", text: "Laying" })
                status.lay = true
            }
            else if(umur[index] > 55 && !status.afkir){
                result.push({ color: "danger", text: "> 55 Mgg" })
                status.afkir = true
            }
        }

        return result
    },
    sort(s) {
        //if s == current sort, reverse
        if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
        }
        this.currentSort = s;
    },
    openDialogForm(name, data) {
      this.curComponent = name
      this.sdataForm = data
      this.dialogDetail = true
    },
    closeDialogForm(res) {
      if (res) {
        this.curComponent = null
        this.sdataForm = null
        this.dialogDetail = false
      }
    },
    openKandang (val) {
        if(val) {
            this.itemsKandang = []
            this.isLoading = true
            this.paramKandang = {farm: val._id, flock: val.flock}
            this.dialog = true
            this.getDataKandang()
        }
    },
    closeKandang () {
        this.dialog = false
        this.itemsKandang = []
        this.isLoading = true
        this.paramKandang = null
    },
    getData () {
      this.isBusy = true
      PopulasiService.getFlockList({farm: this.idF, estimate: (this.getEstimate ? 1 : 0)})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.items = []
                this.isBusy = false
                return;
            }
            this.items = res.data.data
            this.isBusy = false
        })
        .catch((err) => {
          this.items = []
          this.isBusy = false
          return ErrorService.status(err)
        })
    },
    getDataKandang () {
        if(!this.paramKandang){
            ErrorService.message({
              action: 'Peringatan',
              message: 'Tidak ada parameter',
            })
            return
        }

        PopulasiService.getKandangList(this.paramKandang)
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.itemsKandang = []
                    this.isLoading = false
                    return;
                }
                this.itemsKandang = res.data.data
                this.isLoading = false
            })
            .catch((err) => {
                this.itemsKandang = []
                this.isLoading = false
                return ErrorService.status(err)
            })
        },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "UN Data Populasi" }]);
    if (!this.currentUser.menu.nasional_populasi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()    
  },
};
</script>

<style lang="scss">
#unggas-nasional-data-populasi-flock-list{
    .v-context{
        border: 0 solid rgba(0, 0, 0, 0.15) !important;
        border-radius: 0.42rem !important;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
        box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
    }
}
</style>