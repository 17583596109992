<template>
    <div data-app>
        <div class="card card-custom">
            <!--begin::Body-->
            <div v-if="!isBusy" class="card-body h-100">
                <!--begin::Top-->
                <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-45 symbol-light mr-5">
                        <span class="symbol-label">
                            <span class="svg-icon svg-icon-lg svg-icon-primary">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/General/Clipboard.svg"/>
                                <!--end::Svg Icon-->
                            </span>
                        </span>
                    </div>
                    <!--end::Symbol-->

                    <!--begin::Info-->
                    <div class="d-flex flex-column flex-grow-1">
                        <span class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">{{ items.kandang }}</span>
                        <div class="d-flex flex-wrap">
                            <div class="d-flex align-items-center pr-5">
                                <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Home/Clock.svg"/>
                                    <!--end::Svg Icon-->
                                </span>
                                <span class="text-muted font-weight-bold">{{ parseInt(items.tgl_update) | dateIndonesia }}</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Layout/Layout-arrange.svg"/>
                                    <!--end::Svg Icon-->
                                </span>
                                <span class="text-muted font-weight-bold">Flock {{ items.flock }}</span>
                            </div>
                        </div>
                    </div>
                    <!--end::Info-->
                </div>
                
                <div class="separator separator-solid separator-border-1 mt-3"></div>
                <!--end::Top-->
                <perfect-scrollbar
                class="scroll"
                style="max-height: 92%; position: relative;"
                >

                <div class="pt-3 p-0">

                    <!--begin::Item-->
                    <div 
                        v-for="(val, key) in items.chickin" 
                        :key="key" 
                        class="d-flex pt-5 px-5 mb-2 bg-hover-light rounded"
                    >
                        <!--begin::Symbol-->
                        <div class="symbol d-none d-sm-flex symbol-40 symbol-light-success mr-5 mt-1">
                            <span class="symbol-label">
                            <span class="svg-icon svg-icon-info svg-icon-md">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Shopping/Box3.svg"/>
                                <!--end::Svg Icon-->
                            </span>         
                            </span>
                        </div>
                        <!--end::Symbol-->

                        <!--begin::Info-->

                            <div class="d-flex flex-column flex-row-fluid">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center flex-wrap">
                                    <span class="text-dark-75 text-hover-primary mb-1 font-size-md font-weight-bolder pr-6">
                                        Chickin {{ val.tgl_chickin | dateIndonesia }}
                                    </span>
                                    <span class="text-muted font-weight-normal font-size-sm pr-6">{{ val.umur }} Mgg</span>
                                </div>

                                <div class="d-flex align-items-center flex-wrap">
                                    <span class="text-primary font-weight-bold mr-2">
                                        Strain:
                                    </span>
                                    <span class="text-muted font-weight-bold">{{ val.strain }}</span>
                                </div>

                                <div class="d-flex align-items-center flex-wrap">
                                    <span class="text-primary font-weight-bold mr-2">
                                        {{ val.sex }}:
                                    </span>
                                    <span class="text-muted font-weight-bold">{{ (val.chickin - val.deplesi - val.transfer - val.afkir) | nominal }} ekor</span>
                                </div>

                                <!--end::Info-->
                                <div class="separator separator-dashed separator-border-2 mt-1 mb-2"></div>

                            </div>
                        <!--end::Info-->

                    </div>
                    <!--end::Item-->
                </div>
                <!--end::Bottom-->
                </perfect-scrollbar>
            </div>
            <div v-else class="card-body">
                <FacebookLoader></FacebookLoader>
            </div>
            <!--end::Body-->

            <div class="card-footer py-3">
                <button
                class="btn btn-size-sm btn-clean font-weight-bold m-3"
                type="button"
                @click="closeDialog()">
                    <i class="la la-recycle"></i>
                    Close
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { FacebookLoader } from 'vue-content-loader'
import PopulasiService from "@/core/services/api/nasional/populasi.service";
import ErrorService from "@/core/services/error.service";

export default {
    name: "unggas-nasional-data-populasi-detail-sex",
    props: {
        sdata: {
            required: true
        }
    },
    components: {
        FacebookLoader
    },
    data() {
        return {
            isBusy: true,
            items: null
        }
    },
    methods: {
        closeDialog() {
            return this.$emit('close-modal', true)
        },
        getData () {
        PopulasiService.getHenHouseSexList(this.sdata)
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.items = []
                    this.isBusy = false
                    return;
                }
                this.items = res.data.data
                this.isBusy = false
            })
            .catch((err) => {
            this.items = []
            this.isBusy = false
            return ErrorService.status(err)
            })
        },
    },
    mounted() {
        this.getData()
    },
}
</script>