<template>
    <div data-app>
        <div class="card card-custom h-100">
            <!--begin::Body-->
            <div v-if="!isBusy" class="card-body">
                <!--begin::Top-->
                <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-45 symbol-light mr-5">
                        <span class="symbol-label">
                            <span class="svg-icon svg-icon-lg svg-icon-primary">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/General/Clipboard.svg"/>
                                <!--end::Svg Icon-->
                            </span>
                        </span>
                    </div>
                    <!--end::Symbol-->

                    <!--begin::Info-->
                    <div class="d-flex flex-column flex-grow-1">
                        <span class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">{{ items.kandang }}</span>
                        <div class="d-flex flex-wrap">
                            <div class="d-flex align-items-center pr-5">
                                <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Home/Clock.svg"/>
                                    <!--end::Svg Icon-->
                                </span>
                                <span class="text-muted font-weight-bold">{{ parseInt(items.tgl_update) | dateIndonesia }}</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Layout/Layout-arrange.svg"/>
                                    <!--end::Svg Icon-->
                                </span>
                                <span class="text-muted font-weight-bold">Flock {{ items.flock }}</span>
                            </div>
                        </div>
                    </div>
                    <!--end::Info-->
                </div>
                
                <div class="separator separator-solid separator-border-1 mt-3"></div>
                <!--end::Top-->
                <perfect-scrollbar
                class="scroll"
                style="max-height: 92%; position: relative;"
                >

                <div class="pt-3 p-0">

                    <!--begin::Item-->
                    <div 
                        v-for="(val, key) in items.chickin" 
                        :key="key" 
                        class="d-flex pt-5 px-5 mb-2 bg-hover-light cursor-pointer rounded"
                        @click="openDialogForm(val._id)"
                    >
                        <!--begin::Symbol-->
                        <div class="symbol d-none d-sm-flex symbol-40 symbol-light-success mr-5 mt-1">
                            <span class="symbol-label">
                            <span class="svg-icon svg-icon-info svg-icon-md">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Shopping/Box3.svg"/>
                                <!--end::Svg Icon-->
                            </span>         
                            </span>
                        </div>
                        <!--end::Symbol-->

                        <!--begin::Info-->

                            <div class="d-flex flex-column flex-row-fluid">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center flex-wrap">
                                    <span class="text-dark-75 text-hover-primary mb-1 font-size-md font-weight-bolder pr-6">
                                        Chickin {{ val.tgl_chickin | dateIndonesia }}
                                    </span>
                                    <span class="text-muted font-weight-normal font-size-sm pr-6">{{ val.umur }} Mgg</span>
                                </div>

                                <div class="d-flex align-items-center flex-wrap">
                                    <span class="text-primary font-weight-bold mr-2">
                                        Strain:
                                    </span>
                                    <span class="text-muted font-weight-bold">{{ val.strain }}</span>
                                </div>

                                <div class="d-flex align-items-center flex-wrap">
                                    <span class="text-primary font-weight-bold mr-2">
                                        {{ val.sex }}:
                                    </span>
                                    <span class="text-muted font-weight-bold">{{ (val.chickin - val.deplesi - val.transfer - val.afkir) | nominal }} ekor</span>
                                </div>

                                <!--end::Info-->
                                <div class="separator separator-dashed separator-border-2 mt-1 mb-2"></div>

                            </div>
                        <!--end::Info-->

                    </div>
                    <!--end::Item-->
                </div>
                <!--end::Bottom-->
                </perfect-scrollbar>
            </div>
            <div v-else class="card-body">
                <FacebookLoader></FacebookLoader>
            </div>
            <!--end::Body-->

            <div class="card-footer py-3">
                <button  
                @click="openDialogDetail('RiwayatFeedInTake', sdata)"
                class="btn btn-size-sm btn-primary font-weight-bold m-3">
                    <span class="svg-icon svg-icon-md svg-icon-white">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/Home/Book-open.svg" />
                        <!--end::Svg Icon-->
                    </span>
                    Riwayat FeedInTake
                </button>

                <button
                class="btn btn-size-sm btn-clean font-weight-bold m-3"
                type="button"
                @click="closeDialog()">
                    <i class="la la-recycle"></i>
                    Close
                </button>
            </div>
        </div>
    
        <v-dialog
        v-model="openDialog"
        persistent 
        max-width="400">
            <v-card class="px-4">
                <v-card-title class="headline">Tambah Data Feed Intake</v-card-title>
            
                <ValidationObserver ref="form">
                <b-form @submit="onSubmit" v-if="show">
                <v-card-text>
                    <div class="mb-7" role="group">
                    <label for="input-1">Tanggal Feed Intake:</label>
                    <b-button id="input-1" class="form-control form-control-solid text-left" variant="outline-secondary" @click="modalDate = true">{{ form.tgl_feedintake | dateIndonesia }}</b-button>
                    <div v-if="errorDate" class="text-danger mt-1"> The Tanggal Feed Intake field is required </div>
                    </div>

                    <validationProvider :name="`Feed Intake Perekor/perminggu (gr)`" rules="required|min_value:1|max_value:9999999999" v-slot="{ errors, valid }">
                    <b-form-group 
                    :id="`input-group-2`" 
                    label="Feed Intake Perekor/perminggu (gr):" 
                    :label-for="`input-2`"
                    :invalid-feedback="errors[0]"
                    :state="valid">
                        <vue-number-input 
                        :attrs="{ id: `input-2`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                        v-model="form.jumlah"
                        :min="0"
                        :max="9999999999"
                        :step="0.1"
                        required
                        center
                        controls
                        ></vue-number-input>
                    </b-form-group>
                    </validationProvider>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <button 
                    @click="onSubmit" 
                    ref="kt_submit_form"
                    class="btn btn-primary font-weight-bold m-3">
                    <i class="la la-save"></i>
                    Tambah
                    </button>

                    <button
                    class="btn btn-clean font-weight-bold m-3"
                    type="button"
                    @click="closeDialogForm()">
                    <i class="la la-recycle"></i>
                    Batal
                    </button>
                </v-card-actions>
                
                </b-form>
                </ValidationObserver>

            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDetail" persistent scrollable max-width="550px">
            <component v-if="curComponent" :sdata="sdataForm" @close-modal="closeDialogDetail" v-bind:is="curComponent"></component>
        </v-dialog>

        <v-dialog
            ref="dialogDate"
            v-model="modalDate"
            persistent
            width="290px"
        >
            <v-date-picker
            v-model="form.tgl_feedintake"
            scrollable
            color="bg-primary"
            header-color="bg-primary"
            >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="modalDate = false"
            >
                OK
            </v-btn>
            </v-date-picker>
        </v-dialog>
        
    </div>
</template>

<script>
import { FacebookLoader } from 'vue-content-loader'
import PopulasiService from "@/core/services/api/nasional/populasi.service";
import ErrorService from "@/core/services/error.service";

import RiwayatFeedInTake from "@/view/pages/nasional/populasi/form/riwayat/_FeedInTake.vue";

export default {
    name: "unggas-nasional-data-popuasi-form-feedintake",
    props: {
        sdata: {
            required: true
        }
    },
    components: {
        FacebookLoader,
        RiwayatFeedInTake
    },
    data() {
        return {
            isBusy: true,
            items: null,
            show: true,
            dialogDetail: false,
            curComponent: null,
            sdataForm: null,
            openDialog: false,
            modalDate: false,
            errorDate: false,
            form: {
                _id: null,
                _pid: this.sdata._id || null,
                tgl_feedintake: new Date().toISOString().slice(0,10),
                jumlah: 0,
            }
        }
    },
    methods: {
        closeDialog() {
            return this.$emit('close-modal', true)
        },
        openDialogForm(val) {
            this.form._id = val
            this.openDialog = true
        },
        closeDialogForm() {
            this.onReset()
            this.openDialog = false
        },        
        openDialogDetail(name, data) {
            this.curComponent = name
            this.sdataForm = data
            this.dialogDetail = true
        },
        closeDialogDetail(res) {
            if (res) {
                this.curComponent = null
                this.sdataForm = null
                this.dialogDetail = false
            }
        },
        getData () {
        PopulasiService.getHenHouseSexList({populasi: this.sdata._id})
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.items = []
                    this.isBusy = false
                    return;
                }
                this.items = res.data.data
                this.isBusy = false
            })
            .catch((err) => {
            this.items = []
            this.isBusy = false
            return ErrorService.status(err)
            })
        },
        removeSpinner(button) { 
            button.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );
        },
        onSubmit(evt) {
            evt.preventDefault()
            this.$refs.form.validate().then(success => {
                if (!success) {
                    ErrorService.message({
                        action: 'Peringatan',
                        message: 'Silahkan lengkapi form data terlebih dahulu',
                    })
                    return;
                }
                
                // set spinner to submit button
                const submitButton = this.$refs["kt_submit_form"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                // dummy delay
                // setTimeout(() => {
                    PopulasiService.addFeedInTake(this.form)
                        .then((res) => {
                            this.removeSpinner(submitButton)
                            ErrorService.message(res.data)
                            
                            if (res.data.status) {
                                this.closeDialogForm()
                            }
                            return;
                        })
                        .catch((err) => {
                            this.removeSpinner(submitButton)
                            ErrorService.status(err)
                        })

                // }, 1000);
            })
        },
        onReset () {
            this.form.tgl_feedintake = new Date().toISOString().slice(0,10)
            this.form._id = null
            this.form.jumlah = 0

            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    mounted() {
        this.getData()
    },
}
</script>