<template>
    <div>
        <div class="card card-custom h-100">
            <!--begin::Body-->
            <div v-if="!isBusy" class="card-body">
                <!--begin::Top-->
                <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-45 symbol-light mr-5">
                        <span class="symbol-label">
                            <span class="svg-icon svg-icon-lg svg-icon-primary">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/General/Clipboard.svg"/>
                                <!--end::Svg Icon-->
                            </span>
                        </span>
                    </div>
                    <!--end::Symbol-->

                    <!--begin::Info-->
                    <div class="d-flex flex-column flex-grow-1">
                        <span class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">{{ items.kandang }}</span>
                        <div class="d-flex flex-wrap">
                            <div class="d-flex align-items-center pr-5">
                                <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Home/Clock.svg"/>
                                    <!--end::Svg Icon-->
                                </span>
                                <span class="text-muted font-weight-bold">{{ parseInt(items.tgl_update) | dateIndonesia }}</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Layout/Layout-arrange.svg"/>
                                    <!--end::Svg Icon-->
                                </span>
                                <span class="text-muted font-weight-bold">Flock {{ items.flock }}</span>
                            </div>
                        </div>
                    </div>
                    <!--end::Info-->
                </div>
                <!--end::Top-->
                <perfect-scrollbar
                class="scroll"
                style="max-height: 92%; position: relative;"
                >

                <!--begin::Bottom-->
                <div class="pt-3 p-0">
                    <!--begin::Text-->
                    <p v-if="items.keterangan" class="text-dark-75 font-size-lg font-weight-normal pt-5 mb-6">
                        {{ items.keterangan }}
                    </p>
                    <!--end::Text-->

                    <!--begin::Action-->
                    <div class="pt-6">
                        <a class="btn btn-light-primary btn-sm rounded font-weight-bolder font-size-sm px-4 p-2">
                            <span class="svg-icon svg-icon-md">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Shopping/Box3.svg"/>
                                <!--end::Svg Icon-->
                            </span>          
                            {{ items.chickin.length }} Chickin
                        </a>
                    </div>
                    <!--end::Action-->


                    <!--begin::Item-->
                    <div v-for="(val, key) in items.chickin" :key="key" class="d-flex pt-5 mb-4 cursor-pointer" @click="openDialogForm('FormDetailSex', {populasi: items._id, chickin: val._id.split(',')})">
                        <!--begin::Symbol-->
                        <div class="symbol d-none d-sm-flex symbol-40 symbol-light-success mr-5 mt-1">
                            <span class="symbol-label">
                            <span class="svg-icon svg-icon-info svg-icon-md">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Shopping/Box3.svg"/>
                                <!--end::Svg Icon-->
                            </span>         
                            </span>
                        </div>
                        <!--end::Symbol-->

                        <!--begin::Info-->
                        <div class="d-flex flex-column flex-row-fluid">
                            <!--begin::Info-->
                            <div class="d-flex align-items-center flex-wrap">
                                <span class="text-dark-75 text-hover-primary mb-1 font-size-md font-weight-bolder pr-6">
                                    Chickin {{ val.tgl_chickin | dateIndonesia }}
                                </span>
                                <span class="text-muted font-weight-normal font-size-sm pr-6">{{ val.umur }} Mgg</span>
                                <b-badge variant="success">{{ val.strain }}</b-badge>
                            </div>

                            <b-row class="w-100">
                            <b-col v-for="(sex, ids) in val.henhouse" :key="ids" cols="12" sm="6" class="py-4">
                                <div class="d-flex flex-column flex-grow-1">
                                <span class="text-primary font-weight-bold">
                                    {{ sex.sex }}
                                </span>
                                <span class="mb-1 text-muted font-weight-bold">
                                    {{ (sex.chickin - sex.deplesi - sex.transfer - sex.afkir) | nominal }} ekor
                                </span>
                                </div>
                            </b-col>
                            </b-row>


                            <span v-if="val.peringatan" class="text-danger font-size-sm font-weight-normal">
                                {{ val.peringatan }}
                            </span>
                            <!--end::Info-->
                            <div class="separator separator-dashed separator-border-2 mb-2"></div>

                        </div>
                        <!--end::Info-->

                    </div>
                    <!--end::Item-->
                </div>
                <!--end::Bottom-->
                </perfect-scrollbar>
            </div>
            <div v-else class="card-body">
                <FacebookLoader></FacebookLoader>
            </div>
            <!--end::Body-->

            <div class="card-footer py-3">
                <b-dropdown size="sm" variant="primary" class="rounded">
                    <template v-slot:button-content>
                        <span class="svg-icon svg-icon-md svg-icon-white">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/icons/Home/Book-open.svg" />
                            <!--end::Svg Icon-->
                        </span>
                        <span class="d-none d-sm-inline">Riwayat</span>
                    </template>                                        
                    <b-dropdown-item @click="openDialogForm('RiwayatChickin', sdata)">
                        <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/poultry-farm/kandang.svg"/>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="align-self-center">Chickin</span>
                    </b-dropdown-item>      
                    <b-dropdown-item  @click="openDialogForm('RiwayatDeplesi', sdata)">
                        <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/poultry-farm/deplesi.svg"/>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="align-self-center">Deplesi</span>
                    </b-dropdown-item>
                    <b-dropdown-item  @click="openDialogForm('RiwayatTransfer', sdata)">
                        <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/poultry-farm/transfer-cage.svg"/>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="align-self-center">Transfer</span>
                    </b-dropdown-item>
                    <b-dropdown-item  @click="openDialogForm('RiwayatProduksi', sdata)">
                        <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/poultry-farm/production-eggs.svg"/>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="align-self-center">Produksi HE</span>
                    </b-dropdown-item>
                    <b-dropdown-item  @click="openDialogForm('RiwayatAfkir', sdata)">
                        <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/poultry-farm/afkir.svg"/>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="align-self-center">Afkir</span>
                    </b-dropdown-item>
                </b-dropdown>

                <button
                class="btn btn-size-sm btn-clean font-weight-bold m-3"
                type="button"
                @click="closeDialog()">
                    <i class="la la-recycle"></i>
                    Close
                </button>
            </div>        
        </div>
            
        <v-dialog v-model="dialogDetail" persistent scrollable max-width="550px">
            <component v-if="curComponent" :sdata="sdataForm" @close-modal="closeDialogForm" v-bind:is="curComponent"></component>
        </v-dialog>
    </div>
</template>

<script>
import { FacebookLoader } from 'vue-content-loader'
import PopulasiService from "@/core/services/api/nasional/populasi.service";
import ErrorService from "@/core/services/error.service";

import FormDetailSex from "@/view/pages/nasional/populasi/form/_DetailSex.vue";
import RiwayatChickin from "@/view/pages/nasional/populasi/form/riwayat/_Chickin.vue";
import RiwayatDeplesi from "@/view/pages/nasional/populasi/form/riwayat/_Deplesi.vue";
import RiwayatTransfer from "@/view/pages/nasional/populasi/form/riwayat/_Transfer.vue";
import RiwayatProduksi from "@/view/pages/nasional/populasi/form/riwayat/_Produksi.vue";
import RiwayatAfkir from "@/view/pages/nasional/populasi/form/riwayat/_Afkir.vue";

export default {
    name: "unggas-nasional-data-popuasi-form-detail",
    props: {
        sdata: {
            required: true
        }
    },
    components: {
        FacebookLoader,
        FormDetailSex,
        RiwayatChickin,
        RiwayatDeplesi,
        RiwayatTransfer,
        RiwayatProduksi,
        RiwayatAfkir
    },
    data() {
        return {
            dialogDetail: false,
            curComponent: null,
            sdataForm: null,
            isBusy: true,
            items: null,
        }
    },
    methods: {
        closeDialog() {
            return this.$emit('close-modal', true)
        },
        openDialogForm(name, data) {
            this.curComponent = name
            this.sdataForm = data
            this.dialogDetail = true
        },
        closeDialogForm(res) {
            if (res) {
                this.curComponent = null
                this.sdataForm = null
                this.dialogDetail = false
            }
        },
        getData () {
        PopulasiService.getHenHouseList({populasi: this.sdata._id})
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.items = []
                    this.isBusy = false
                    return;
                }
                this.items = res.data.data
                this.isBusy = false
            })
            .catch((err) => {
            this.items = []
            this.isBusy = false
            return ErrorService.status(err)
            })
        },
    },
    mounted() {
        this.getData()
    },
}
</script>